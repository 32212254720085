.about-us{
    padding: 20px;
    align-items: center;
}

.process_num{
    position: absolute;
    background-image: linear-gradient(200deg,#1778c6,rgba(154,207,219,.35));
    font-size: 30px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

