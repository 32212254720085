.footer{
    color: #606161;
    font-size: 14px;
}
.footer .footer-fix {
    background-color:#5f6060;
    padding: 5px;
}

.footer .footer-contact {
    background-color:#e4e4e4;
    padding: 30px;
}

.footer .footer-contact p {
    font-size: 15px;
    font-weight: 700;
    text-transform:uppercase;
}
.footer .footer-contact .footer-logo {
    width: 100px;
}


.footer-copyright{
    padding: 10px;
    text-align: center;
    color: antiquewhite;
}

.social-icon {
    position: relative;
    z-index: 1;
    display: inline-block;
    margin-right: 10px;
    overflow: hidden;
    vertical-align: middle;
    cursor: pointer;
    border-radius: 50%;
    -webkit-box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18), 0 4px 15px 0 rgba(0,0,0,0.15);
    box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18), 0 4px 15px 0 rgba(0,0,0,0.15);
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    width: 47px;
    height: 47px;
}

.fa {
    padding: 12px 3px 10px 0px;
    width: 50px;
    text-align: center;
    text-decoration: none;
}
  
.fa:hover {
    opacity: 0.7;
}

.fa-facebook {
    background: #3B5998;
    color: white;
}
.fa-twitter {
    background: #55ACEE;
    color: white;
}
.fa-google-plus {
    background: #dd4b39;
    color: white;
}
.fa-linkedin {
    background: #007bb5;
    color: white;
}
.fa-youtube {
    background: #bb0000;
    color: white;
}
.fa-instagram {
    background: #e4405f;
    color: white;
}
/* .social-icon i {
    display: inline-block;
    width: inherit;
    color: #fff; 
    text-align: center;
    line-height: 50px; 
    background-color:#606161; 
} 

.social-icon i:hover {
    color: #d6d7de;
}

.social-icon span {
    align-content: center;
} */