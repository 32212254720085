.navbar {
    /* box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5); */
    width: 100%;
}
.navbar .navbar-brand > .header-logo{
    width: 75px;
} 
.navbar-nav > a {
    transition: 0.3s ease;
    color: #333333;
    font-size: 12px;
    font-weight: 700;    
    text-decoration: none;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-top-left-radius:1ch ;
    border-top-right-radius:1ch ;
    border-bottom-left-radius:1ch ;
    border-bottom-right-radius:1ch ;
    padding: 10px 25px 10px 25px;
}
.navbar-nav > a:hover {
    border-top: 4px solid #1778c6;
    border-bottom: 4px solid #1778c6;
    text-decoration: none;
    color: #333333;
}