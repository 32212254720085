.hexagon {
  display: inline-block;
  position: relative;
  width: 170px;
  height: 170px;
  background: #1778c6;
  -webkit-clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
  clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
  text-align: center;
}

.hexagon .inner {
  display: inline-block;
  position: relative;
  top: 2px;
  width: 166px;
  height: 166px;
  background: #FFF;
  color: #1778c6;
  -webkit-clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
  clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
}

.hexagon .inner .center {
  position: relative;
  top:65px;
}
div.hexagon .core-value-icon {
  position: absolute;
  top:50px;
  left: 50px;
  opacity: 1;
  overflow: hidden;
  transform: translateY(0px);
  transition: .5s ease;
}
div.hexagon:hover .core-value-icon {
  opacity: 0;
  transform: translateY(60px);
  transition: .5s ease;
  overflow: hidden;
}

div.hexagon .core-value-title {
  position: absolute;
  top:60px;
  left: 30px;
  opacity: 0;
  font-weight: 800;
  font-size: inherit;
  width: 100px;
  transform: translateY(50px);
  transition: .5s ease;
}

div.hexagon:hover .core-value-title {
  opacity: 1;
  overflow: hidden;
  transform: translateY(0px);
  transition: .5s ease;
}

.core-value-content {
  position: absolute;
  opacity: 0;
  transform: translateX(0px);
  transition: .5s ease;
  font-size: 14px;
  top: 10%;
  
}

div.hexagon:hover ~ .content-top, div.hexagon:hover ~ .content-left {
  opacity: 0;
  transform: translateX(100px);
  transition: .5s ease;
}

div.hexagon:hover ~ .content-bottom, div.hexagon:hover ~ .content-right {
  opacity: 0;
  transform: translateX(-100px);
  transition: .5s ease;
}

.content-top {
  left: -10%;
}
.content-left {
  left: -130%;
}

.content-right {
  right: -130%;
}
.content-bottom {
  right: -10%;
}

.arrow-up {
  width: 120px;
  transform: rotate(270deg);
}

.arrow-down {
  width: 120px;
  transform: rotate(90deg);
}

.arrow-right{
  width: 120px;
  
   
}
.arrow-left{
  width: 120px;
  transform: rotate(180deg);
  
}

.line {
  margin-top: 14px;
  width: 90px;
  background: linear-gradient(200deg,#1778c6,rgba(154,207,219,.35));
  height: 10px;
  float: left;
}

.point {
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 30px solid #1778c6;
  float: left;
}